body {
  font-family: var(--font-family);
  color: var(--gray-900);
  font-size: var(--is-md);
  font-weight: var(--is-400);
  line-height: 1.5;
  overflow-x: hidden;
}

main {
  flex: 1 0 auto;
  background-color: var(--white-100);
}

select,
input,
table {
  font-family: var(--font-family);
}

.cursor-pointer {
  cursor: pointer;
}

.height-70 {
  height: 70vh;
}
