.loader {
  position: fixed;
  cursor: not-allowed;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--gray-rgba);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    -webkit-animation: spin 0.8s linear infinite;
    -moz-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
